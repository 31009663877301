<template>
  <div class="card-description">
    <p class="card-title">{{ title }}</p>
    <template v-if="types && types.length">
      <p v-for="item in types" :key="item" class="card-title_small">
        {{ item }}
      </p>
    </template>
    <p v-else class="card-title_small">{{ type }}</p>
  </div>
</template>

<script lang="js">
import { mapGetters } from 'vuex';

export default {
  name: 'CardPartnerType',
  props: {
    types: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: ''
    }
  },
  computed: {
    title() {
      if (this.layer === 'partners') {
        return 'Тип партнера'
      }
      return 'Вид объекта'
    },
    ...mapGetters('layer', ['layer'])
  }
}
</script>

<style scoped></style>
