<template>
  <div v-if="entity" :class="{ 'expand': mode }" class="card-wrapper">
    <div v-if="mode" class="card-wrapper__drag"></div>
    <img v-else alt="карточка свернута" src="@/assets/images/svg/up.svg"
      class="card-wrapper__drag card-wrapper__drag_up">
    <a class="prev-link" @touchstart="setPosition($event)">
      <picture>
        <source srcset="../../assets/images/svg/prev.svg" media="(min-width: 769px)">
        <source srcset="../../assets/images/svg/prev_white.svg" media="(max-width: 768px)">
        <img src="@/assets/images/svg/prev.svg" alt="" class="prev-link__icon" @click="returnToResults()">
      </picture>
      <span v-if="mode" @click="returnToResults()">Назад</span>
      <span v-else @click="returnToResults()"></span>
      <img src="@/assets/images/svg/close_white.svg" role="button" alt="закрыть" class="card-wrapper__close"
        @click="closeCard()">
    </a>
    <div v-if="mode" class="card-wrapper__main">
      <CardImages v-if="entity.images && entity.images.length && entity.images[0].thumbnail_link"
        :images="entity.images" />
      <CardHeader :title="entity.name" :certifications="entity.certifications" />
      <CardInn v-if="isAuthenticated && entity.inn" :inn="entity.inn" />
      <CardPhone :phone_1="entity.phone_1" :phone_2="entity.phone_2" />
      <CardAddress :address="entity.address" />
      <CardYearCompletion v-if="entity.year_of_completion" :year="entity.year_of_completion" />
      <CardWebSite :webSite="entity.web_site" />
      <CardEmail :email="entity.email_1" />
      <CardEmail :email="entity.email_2" />
      <CardPartnerType :types="entity.types" :type="entity.type" />
      <CardAttributes v-if="entity.attributes && entity.attributes.length" :attributes="entity.attributes" />
      <CardProducts :groups="entity.product_groups" />
      <CardDescription v-if="entity.developer" :title="'Девелопер'" :description="entity.developer" />
      <CardDescription v-if="entity.project_organization" :title="'Проектная организация'"
        :description="entity.project_organization" />
      <CardDescription v-if="isAuthenticated && entity.partners" :title="'Партнер(ы) РЕХАУ'"
        :description="entity.partners" />
      <CardDescription v-if="entity.description && entity.description.length" :title="'Описание'"
        :description="entity.description" :isHtml="true" />
      <CardSocials :socials="entity.social_networks" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import api from '@/requests';
import CardHeader from '@/components/card/CardHeader.vue';
import CardInn from '@/components/card/CardInn.vue';
import CardPhone from '@/components/card/CardPhone.vue';
import CardAddress from '@/components/card/CardAddress.vue';
import CardWebSite from '@/components/card/CardWebSite.vue';
import CardEmail from '@/components/card/CardEmail.vue';
import CardPartnerType from '@/components/card/CardPartnerType.vue';
import CardProducts from '@/components/card/CardProducts.vue';
import CardAttributes from '@/components/card/CardAttributes.vue';
import CardDescription from '@/components/card/CardDescription.vue';
import CardSocials from '@/components/card/CardSocials.vue';
import CardYearCompletion from '@/components/card/CardYearCompletion.vue';
import CardImages from './CardImages.vue';

export default {
  name: 'CardEntity',
  components: {
    CardImages,
    CardHeader,
    CardInn,
    CardPhone,
    CardAddress,
    CardWebSite,
    CardEmail,
    CardPartnerType,
    CardProducts,
    CardAttributes,
    CardDescription,
    CardSocials,
    CardYearCompletion
  },
  data() {
    return {
      mode: 1,
      startPosition: null
    };
  },
  async mounted() {
    this.startLoading();

    this.setScreenHeight();

    // Добавляем обработчик на изменение размеров окна
    window.addEventListener('resize', this.setScreenHeight);

    if ('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0) {
      document.addEventListener('touchend', e => {
        this.updateMode(e.changedTouches[0].clientY);
      });
    }

    if (this.itemId) {
      await this.fetchPartner(this.itemId);
    } else {
      this.setEntityId(this.$route.params.id);
    }

    this.stopLoading();
  },
  beforeDestroy() {
    this.$store.dispatch('addVisited', this.itemId);
    this.setEntityId(null);
    this.setEntity(null);
    window.removeEventListener('resize', this.setScreenHeight); // Удаляем обработчик при уничтожении компонента
  },
  watch: {
    async itemId(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        await this.fetchPartner(newValue);
      }
    }
  },
  methods: {
    async fetchPartner(id) {
      this.startLoading();
      let item = null;

      if (this.layer === 'partners') {
        item = await api.partners.partner(id);
      } else {
        item = await api.objects.get(id);
      }

      this.setEntity(item);

      this.setCurrentLocation({ coords: [item.latitude, item.longitude], zoom: 15 });
      this.stopLoading();
    },
    setPosition(event) {
      this.startPosition = event.touches[0].clientY;
    },
    returnToResults() {
      this.$router.go(-1);
    },
    setScreenHeight() {
      // Расчет высоты видимой области и установка CSS-переменной
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    updateMode(finishPosition) {
      if (!this.startPosition) { return; }
      if (finishPosition - this.startPosition > 50) {
        this.mode = this.mode === 0 ? 0 : this.mode - 1;
      } else if ((finishPosition - this.startPosition < -50)) {
        this.mode = this.mode === 2 ? 0 : this.mode + 1;
      }
      this.startPosition = null;
    },
    closeCard() {
      this.$router.push('/partners');
    },
    ...mapActions('loader', ['startLoading', 'stopLoading']),
    ...mapActions('entity', ['setEntity', 'setEntityId']),
    ...mapActions('location', ['setCurrentLocation'])
  },
  computed: {
    entity() {
      return this.item;
    },
    ...mapGetters('entity', ['itemId', 'item']),
    ...mapGetters('user', ['isAuthenticated']),
    ...mapGetters('layer', ['layer'])
  }
}
</script>

<style lang="scss" scoped></style>
